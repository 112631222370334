<template>
    <div class="field-table flex-1 flex flex-col  max-h-full max-w-full  min-h-full text-base-content">
        <SubHeader 
            :showDropDown="true"
            :dropDownOptions="dropDownOptions"
            :handleDropDownSelection="handleFilterActivity"
            :customCrumbLists="customCrumbLists"
        />
        <!-- <div class="flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar"> -->
            <div class="flex flex-1 flex-col flex-grow items-end mb-3">
                <dropdown
                searchable
                class="bg-primary-300"
                min-width="160px"
                placeholder="Client Name"
                :config="{ label: 'title', trackBy: 'value'}"
                :options="clients"
                @input="(value) => handleFilterActivity(value)"
                >
                </dropdown>
            </div>
            <vue-good-table 
                :columns="columns" 
                :isLoading="loading" 
                :rows="rows" 
                :pagination-options="{ enabled: true, mode: 'remote', dropdownAllowAll: false}"
                :totalRows="pageData.total_count"
                @on-page-change="onPageChange($event)"
                @on-per-page-change="onPageChange($event, true)"
                :per-page-options="customPerPageOptions"
            >
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field == 'credit_source_name'" class="flex justify-center" >
                        {{props.row.credit_source_name}}
                    </div>
                    <div v-if="props.column.field === 'sub_feature_name'" class='' :title="props.row.sub_feature_name">
                        {{ getNrnName(props.row.sub_feature_name) }}
                    </div>
                    <div v-if="props.column.field === 'output'" class='' :title="props.row.output">
                        {{ getNrnName(props.row.output) }}
                    </div>
                    <div v-if="props.column.field == 'credit_per_hit'" class="flex justify-center" :class="{'text-primary' : checkCustomData(props.row)}">
                        {{ showCreditHit(props.row) }}
                    </div>
                    <div v-if="props.column.field == 'service_charge_per_hit'" class="flex justify-center" :class="{'text-primary' : checkCustomData(props.row)}">
                        {{ showServiceCharge(props.row) }}
                    </div>
                </template>
                <template #emptystate>
                    <div class="alert alert-info py-5 my-5" v-if="!isLoading">No Data Found</div>
                    <div v-else class="py-16"></div>
                </template>
                <template #loadingContent>
                    <div class="w-full py-10 justify-center flex">
                        <Loader />
                    </div>
                </template>
            </vue-good-table>
        <!-- </div> -->
    </div>
</template>
<script>
    import SubHeader from "@/components/SubHeader";
    import { creditAndSourceList, getclientId } from "../services"
    import dropdown from "@shared/components/dropdown-base";
    import Loader from "@/components/loader";

    export default {
        name: "credit-packs",
        title: "Credit Admin",
        components: {
            SubHeader,
            Loader,
            dropdown
        },
        data() {
            return {
                columns: [{
                    label: "Source",
                    field: "credit_source_name",
                    sortable: true
                },{
                    label: "Input",
                    field: "sub_feature_name",
                    sortable: true
                },{
                    label: "Output",
                    field: "output",
                    sortable: true
                },{
                    label: "Credits/Hits",
                    field: "credit_per_hit",
                    sortable: true
                },{
                    label: "Service Charge/Hits",
                    field: "service_charge_per_hit",
                    sortable: true
                } ],
                showDropDown: false,
                dropDownOptions: {},
                rows: [],
                clients: [],
                pageData: {
                    total_count: 0,
                },
                currentPageData: {
                    page: 1,
                    limit: 10,
                },
                customPerPageOptions: [10, 20, 30, 40, 50],
                loading: false,
                customCrumbLists: [{name: 'Credit & Source'}],
                currentClient: {},
                tenantId: null,
                
            }
        },
        async mounted() {
            this.loading = true
            await this.setCreditSourceList()
            this.tenantId = this.$store.getters.getTenantId;
            let payload ={
                tenant_id: this.tenantId,
                count: 1000 // for now adding static count because otherwise api will only give 10 data (API should be modified.)
            }
            let {data} = await getclientId(payload);
            this.clients = data?.data.map((res)=>{return {"title": res.name, "value": res.id}})
            this.showDropDown = true;
            this.loading = false;
        },
        methods: {
            getNrnName(value) {
                if (value?.length > 90) {
                    return value.substring(0, 40) + "..."
                } else {
                    return value;
                }
            },
            showCreditHit(rowData) {
                let credit_per_hit = rowData?.credit_per_hit
                if (this.currentClient && this.currentClient?.value) {
                    let customClient = rowData?.clients?.find(clnt => clnt?.client_id == this.currentClient?.value);
                    if (customClient && customClient?.client_id) {
                        credit_per_hit = customClient.credit_per_hit;
                    }
                } else {
                    let customTenant = rowData?.tenants?.find(tnt => tnt?.tenant_id == this.tenantId)
                    if (customTenant && customTenant?.tenant_id) {
                        credit_per_hit = customTenant.credit_per_hit;
                    }
                }
                return credit_per_hit;
            },
            showServiceCharge(rowData) {
                let service_charge_per_hit = rowData?.service_charge_per_hit
                if (this.currentClient && this.currentClient?.value) {
                    let customClient = rowData?.clients?.find(clnt => clnt?.client_id == this.currentClient?.value);
                    if (customClient && customClient?.client_id) {
                        service_charge_per_hit = customClient.service_charge_per_hit;
                    }
                } else {
                    let customTenant = rowData?.tenants?.find(tnt => tnt?.tenant_id == this.tenantId)
                    if (customTenant && customTenant?.tenant_id) {
                        service_charge_per_hit = customTenant.service_charge_per_hit;
                    }
                }
                return service_charge_per_hit;
            },
            checkCustomData(rowData) {
                let isCustomData = false;
                if (this.currentClient && this.currentClient?.value) {
                    if (rowData?.clients?.length > 0 && rowData?.clients?.some(clnt => clnt?.client_id == this.currentClient?.value)) {
                        isCustomData = true;
                    }
                } else {
                    if (rowData?.tenants?.length > 0) {
                        isCustomData = true;
                    }
                }
                return isCustomData;
            },
            async setCreditSourceList() {
                try {
                    this.loading = true;
                    // let clinetId = this.$store.getters.getClientId
                    let qParams = new URLSearchParams(this.currentPageData);
                    const { data } = await creditAndSourceList(qParams);
                    this.rows = data.data;
                    this.pageData = data?.pagination_info;
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    if (Array.isArray(error?.response?.data?.detail)) {
                        this.$toast.error(error?.response?.data?.detail?.[0]?.msg);
                    } else {
                        this.$toast.error(error?.response?.data?.detail);
                    }
                }
            },
            async onPageChange(data, resetPage = false) {
                this.currentPageData = {
                    page: data.currentPage,
                    limit: data.currentPerPage,
                };
                if (resetPage) this.currentPageData.page = 1;
                await this.setCreditSourceList();
            },
            async handleFilterActivity(value) {
                this.currentClient = value
            }
        }

    }
</script>
<style lang="scss" scoped>
@import "@/assets/styles/functions.scss";

.dropRight{
    background-color: #0d69d5;
}

</style>
